import {Link} from 'react-router-dom';
import { useAuthContext } from "../contexts/authContext";
import { LOGIN, PRIVATE } from "../config/router/paths";



export default function Home() {
  const {isAuthenticated} = useAuthContext();
  return (
    <div>
      <p>
        {isAuthenticated ? (
          <Link to={PRIVATE}>Sección privada</Link>
        ) : (
          <Link to={LOGIN}>Iniciar sesión</Link>
        )}
      </p>
    </div>
  );
}
