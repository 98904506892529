import React, {useState, useEffect, useCallback} from "react";
import { useForm } from "react-hook-form";
import { useArticuloContext } from "../../contexts/articuloContext";
import { MIS_ARTICULOS } from "../../config/router/paths";
import {useLocation, useNavigate} from "react-router-dom";

const ActualizarArticulo = () => {

    const location = useLocation();
    const articulo = location.state.data;

    const navigate = useNavigate();

    const {actualizarArticulo} = useArticuloContext();

    const [isLoading, setIsLoading] = useState(false);
    const [numAuthors, setNumAuthors] = useState(location.state.data.numAuthors);
    const [fileSizeError, setFileSizeError] = useState(false);

    const {
        register,
        handleSubmit,
        setError,
        reset,
        formState: { errors, isDirty },
        setValue,
    } = useForm();

    useEffect(() => {
        if (articulo) {
            Object.keys(articulo).forEach((key) => {
                setValue(key, articulo[key]);
            });
        }
    }, [articulo, setValue]);

    // Definir onSubmit con useCallback para evitar cambios en cada render
    const onSubmit = useCallback( async (data) => {
        if (!isDirty) {
            // No hay cambios, regresar a la página de MisArticulos
            navigate(MIS_ARTICULOS);
            return;
        }
        if (fileSizeError) {
            return;
        }

        setIsLoading(true);

        // Crear un objeto FormData
        const formData = new FormData();

        // Agregar campos de autores al FormData
        for (let i = 1; i <= numAuthors; i++) {
            formData.append(`name${i}`, data[`name${i}`]);
            formData.append(`lastname${i}`, data[`lastname${i}`]);
            formData.append(`email${i}`, data[`email${i}`]);
            formData.append(`country${i}`, data[`country${i}`]);
            formData.append(`institution${i}`, data[`institution${i}`]);
            formData.append(`orcid${i}`, data[`orcid${i}`]);
        }

        // Agregar campos adicionales al FormData
        formData.append("numAuthors", data.numAuthors);
        formData.append("title", data.title);
        formData.append("abstract", data.abstract);
        formData.append("keywords", data.keywords);
        formData.append("topic", data.topic);

        // Agregar el archivo PDF al FormData
        formData.append("pdfArchivo", data.pdfArchivo[0]);

        await actualizarArticulo(formData, articulo._id);

        reset();

        navigate(MIS_ARTICULOS);

        setIsLoading(false);
    },[actualizarArticulo, navigate, numAuthors, articulo._id, reset, isDirty, fileSizeError]);


    // Función de validación para el campo "Código ORCID"
    const validateOrcid = (value) => {
        if (!value) {
            return true; // Si el campo está vacío, no se realiza la validación
        }

        const regex = /^(https?:\/\/orcid\.org\/)?(\d{4}-){3}\d{3}[0-9X]$/i;
        return regex.test(value) || "Ingrese un código ORCID válido (ej. https://orcid.org/0000-0003-4214-7505)";
    };

    // Función para generar los campos de autor dinámicamente
    const generateAuthorFields = (num) => {
        const fields = [];
        for (let i = 1; i <= num; i++) {
            fields.push(
                <div key={i}>
                    <h5 className="mb-3 fw-bold">Autor(a) {i}</h5>
                    <div className="row">
                        <div className="col-md-6 mb-3">
                            <label htmlFor={`name${i}`} className="form-label small fw-bold">
                                Nombres (First name):
                            </label>
                            <input
                                type="text"
                                className={`form-control ${errors[`name${i}`] ? "is-invalid" : ""}`}
                                id={`name${i}`}
                                {...register(`name${i}`, { required: true })}
                            />
                            {errors[`name${i}`] && (
                                <div className="invalid-feedback">
                                    Este campo es requerido
                                </div>
                            )}
                        </div>
                        <div className="col-md-6 mb-3">
                            <label htmlFor={`lastname${i}`} className="form-label small fw-bold">
                                Apellidos (Last name):
                            </label>
                            <input
                                type="text"
                                className={`form-control ${errors[`lastname${i}`] ? "is-invalid" : ""}`}
                                id={`lastname${i}`}
                                {...register(`lastname${i}`, { required: true })}
                            />
                            {errors[`lastname${i}`] && (
                                <div className="invalid-feedback">
                                    Este campo es requerido
                                </div>
                            )}
                        </div>
                        <div className="col-md-6 mb-3">
                            <label htmlFor={`email${i}`} className="form-label small fw-bold">
                                Correo electrónico (Email):
                            </label>
                            <input
                                type="email"
                                className={`form-control ${errors[`email${i}`] ? "is-invalid" : ""}`}
                                id={`email${i}`}
                                {...register(`email${i}`, { required: true })}
                            />
                            {errors[`email${i}`] && (
                                <div className="invalid-feedback">
                                    Este campo es requerido y debe ser un correo válido
                                </div>
                            )}
                        </div>
                        <div className="col-md-6 mb-3">
                            <label htmlFor={`country${i}`} className="form-label small fw-bold">
                                País (Country):
                            </label>
                            <input
                                type="text"
                                className={`form-control ${errors[`country${i}`] ? "is-invalid" : ""}`}
                                id={`country${i}`}
                                {...register(`country${i}`, { required: true })}
                            />
                            {errors[`country${i}`] && (
                                <div className="invalid-feedback">
                                    Este campo es requerido
                                </div>
                            )}
                        </div>
                        <div className="col-md-6 mb-3">
                            <label htmlFor={`institution${i}`} className="form-label small fw-bold">
                                Institución (Institution):
                            </label>
                            <input
                                type="text"
                                className={`form-control ${errors[`institution${i}`] ? "is-invalid" : ""}`}
                                id={`institution${i}`}
                                {...register(`institution${i}`, { required: true })}
                            />
                            {errors[`institution${i}`] && (
                                <div className="invalid-feedback">
                                    Este campo es requerido
                                </div>
                            )}
                        </div>
                        <div className="col-md-6 mb-3">
                            <label htmlFor={`orcid${i}`} className="form-label small fw-bold">
                                Código ORCID (ORCID Code):
                            </label>
                            <input
                                type="text"
                                placeholder="Opcional (https://orcid.org/0000-0003-4214-7505)"
                                className={`form-control ${errors[`orcid${i}`] ? "is-invalid" : ""}`}
                                id={`orcid${i}`}
                                {...register(`orcid${i}`, { validate: validateOrcid })}
                            />
                            {errors[`orcid${i}`] && (
                                <div className="invalid-feedback">
                                    Este campo debe es opcional, si decide diligenciarlo debe tener formato ORCID (https://orcid.org/0000-0003-4214-7505)
                                </div>
                            )}
                        </div>
                    </div>
                    <hr className="my-3"/>
                </div>

            );
        }
        return fields;
    };

    // Función para manejar cambios en el campo de cantidad de autores
    const handleNumAuthorsChange = (e) => {
        const value = parseInt(e.target.value, 10);
        if (!isNaN(value) && value >= 1 && value <= 6) {
            setNumAuthors(value);
        }
    };


    // Función para manejar el evento onInput del campo de entrada de archivo
    const handleFileInput = async (e) => {
        const file = e.target.files[0];

        // Si no hay archivo seleccionado, no realizamos la validación
        if (!file) {
            setFileSizeError(false);
            setError("pdfArchivo", null); // Limpiamos el error del campo pdfArchivo en el formulario
            return;
        }

        const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5 MB

        if (file && file.size > MAX_FILE_SIZE) {
            setFileSizeError(true);
            setError("pdfArchivo", {
                type: "manual", // Establecemos el tipo de error manualmente
                message: "El tamaño del archivo debe ser inferior a 5MB",
            });
        } else {
            // Si el tamaño del archivo es válido, realizamos una validación asíncrona para asegurar que el archivo sea un PDF
            setFileSizeError(false);
            setError("pdfArchivo", null); // Limpiamos el error del campo pdfArchivo en el formulario

            try {
                await validatePDFFile(file);
            } catch (error) {
                setError("pdfArchivo", {
                    type: "manual",
                    message: "El archivo debe ser de tipo PDF",
                });
            }
        }
    };

    // Función de validación asíncrona para verificar si el archivo es de tipo PDF
    const validatePDFFile = async (file) => {
        return new Promise((resolve, reject) => {
            if (file.type === "application/pdf") {
                resolve();
            } else {
                reject();
            }
        });
    };

    // Función de validación para el campo "abstract"
    /*const validateAbstract = (value) => {
        const wordCount = value.trim().split(/\s+/).length;
        return (wordCount >= 200 && wordCount <= 300) || 'El resumen debe tener entre 200 y 300 palabras.';
    };*/

    // Función de validación para el campo "keywords"
    const validateKeywords = (value) => {
        // Dividir el string en palabras usando la coma como separador
        const words = `${value}`.trim().split(',');

        const isValidWords = words.every((word) => word.trim().length >= 3);
        // Verificar la cantidad de palabras en el arreglo
        const wordCount = words.length;

        // Verificar si el string tiene entre 3 y 7 palabras
        return (
            (wordCount >= 3 && wordCount <= 7 && isValidWords) ||
            'Debe ingresar entre 3 y 7 palabras clave separadas por coma.'
        );
    };

    return (
        <div className="container mt-3 card border-0 shadow-lg p-5">

            <h3 className="my-4 text-center fw-bold">Formulario de actualización de artículo CIETA</h3>

            <form onSubmit={handleSubmit(onSubmit)} className="needs-validation">
                <div className="col-md-3 form-group mb-3">
                    <label htmlFor="numAuthors" className="form-label small fw-bold">
                        Cantidad de autores del artículo (máximo 6):
                    </label>
                    <input
                        type="number"
                        className={`form-control ${errors.numAuthors ? "is-invalid" : ""} `}
                        id="numAuthors"
                        {...register("numAuthors", {
                            required: true,
                            min: 1,
                            max: 6,
                        })}
                        value={numAuthors}
                        onChange={handleNumAuthorsChange}
                    />
                    {errors.numAuthors && (
                        <div className="invalid-feedback">
                            Este campo es requerido y debe estar entre 1 y 6.
                        </div>
                    )}
                </div>

                <hr className="my-4"/>


                {/* Generar campos de autor dinámicamente */}
                {generateAuthorFields(numAuthors)}


                <h4 className="mt-4 fw-bold text-center">Información del artículo</h4>

                <div className="mb-3">
                    <label htmlFor="title" className="form-label small fw-bold">
                        Título del artículo (Title):
                    </label>
                    <input
                        type="text"
                        className={`form-control ${errors.title ? "is-invalid" : ""}`}
                        id="title"
                        {...register("title", { required: true })}
                    />
                    {errors.title && (
                        <div className="invalid-feedback">
                            Este campo es requerido
                        </div>
                    )}
                </div>

                <div className="mb-3">
                    <label htmlFor="abstract" className="form-label small fw-bold">
                        Resumen (Abstract):
                    </label>
                    <textarea
                        placeholder="Entre 200 y 300 palabras"
                        className={`form-control ${errors.abstract ? "is-invalid" : ""}`}
                        id="abstract"
                        {...register("abstract", {
                            required: true,
                        })}
                    />
                    {errors.abstract && (
                        <div className="invalid-feedback">
                            Este campo es requerido y debe tener entre 200 a 300 palabras
                        </div>
                    )}
                </div>

                <div className="mb-3">
                    <label htmlFor="keywords" className="form-label small fw-bold">
                        Palabras clave (Keywords) separadas por espacios:
                    </label>
                    <input
                        type="text"
                        placeholder="Entre 3 y 7 palabras"
                        className={`form-control ${errors.keywords ? "is-invalid" : ""}`}
                        id="keywords"
                        {...register("keywords", {
                            required: true,
                            validate: validateKeywords
                        })}
                    />
                    {errors.keywords && (
                        <div className="invalid-feedback">
                            Este campo es requerido debe tener entre 3 a 7 palabras
                        </div>
                    )}
                </div>

                <div className="mb-3">
                    <label htmlFor="topic" className="form-label small fw-bold">
                        Temática del artículo (Subject of the article):
                    </label>
                    <select
                        className={`form-control ${errors.topic ? "is-invalid" : ""}`}
                        id="topic"
                        {...register("topic", { required: true })}
                    >
                        <option value="">Seleccione una temática...</option>
                        <optgroup label="Ingeniería Eléctrica">
                            <option value="Electrónica de Potencia y Energías Renovables">Electrónica de Potencia y Energías Renovables</option>
                            <option value="Electromagnetismo y Microondas">Electromagnetismo y Microondas</option>
                            <option value="Máquinas eléctricas">Máquinas eléctricas</option>
                            <option value="Redes de distribución">Redes de distribución</option>
                            <option value="Eficiencia energética y optimización de sistemas">Eficiencia energética y optimización de sistemas</option>
                        </optgroup>
                        <optgroup label="Ingeniería Electrónica">
                            <option value="Electrónica Analógica y Digital">Electrónica Analógica y Digital</option>
                            <option value="Circuitos Integrados y Sistemas Embebidos">Circuitos Integrados y Sistemas Embebidos</option>
                            <option value="Microelectrónica y Nanotecnología">Microelectrónica y Nanotecnología</option>
                            <option value="Sensores y Dispositivos Inteligentes">Sensores y Dispositivos Inteligentes</option>
                            <option value="Sistemas Embebidos en Tiempo Real">Sistemas Embebidos en Tiempo Real</option>
                            <option value="Dispositivos Ópticos y Fotónicos">Dispositivos Ópticos y Fotónicos</option>
                            <option value="Inteligencia Artificial y Aprendizaje Automático en Electrónica">Inteligencia Artificial y Aprendizaje Automático en Electrónica</option>
                        </optgroup>
                        <optgroup label="Ingeniería Industrial">
                            <option value="Gestión de Operaciones y Producción">Gestión de Operaciones y Producción</option>
                            <option value="Gestión de la Calidad">Gestión de la Calidad</option>
                            <option value="Gestión de Proyectos">Gestión de Proyectos</option>
                            <option value="Ingeniería Económica">Ingeniería Económica</option>
                            <option value="Ergonomía y Seguridad Laboral">Ergonomía y Seguridad Laboral</option>
                            <option value="Ingeniería Organizacional y Gestión de Recursos Humanos">Ingeniería Organizacional y Gestión de Recursos Humanos</option>
                            <option value="Gestión Ambiental y Sostenibilidad">Gestión Ambiental y Sostenibilidad</option>
                            <option value="Ingeniería de Servicios">Ingeniería de Servicios</option>
                        </optgroup>
                        <optgroup label="Ingeniería Mecatrónica">
                            <option value="Control de Procesos y Automatización Industrial">Control de Procesos y Automatización Industrial</option>
                            <option value="Diseño e integración de sistemas mecatrónicos">Diseño e integración de sistemas mecatrónicos</option>
                            <option value="Robótica y sistemas autónomos">Robótica y sistemas autónomos</option>
                            <option value="Automatización y Robótica en la Salud">Automatización y Robótica en la Salud</option>
                            <option value="Innovación y Diseño de Productos">Innovación y Diseño de Productos</option>
                        </optgroup>
                        <optgroup label="Ingeniería de Sistemas">
                            <option value="Realidad Virtual y Realidad Aumentada">Realidad Virtual y Realidad Aumentada</option>
                            <option value="Computación Cuántica y Tecnologías Emergentes">Computación Cuántica y Tecnologías Emergentes</option>
                            <option value="Desarrollo de software">Desarrollo de software</option>
                            <option value="Computación en la nube">Computación en la nube</option>
                            <option value="Algoritmos de Optimización">Algoritmos de Optimización</option>
                            <option value="Sistemas Inteligentes y Machine Learning">Sistemas Inteligentes y Machine Learning</option>
                        </optgroup>
                        <optgroup label="Ingeniería de Telecomunicaciones">
                            <option value="Procesamiento digital de Señales e imágenes">Procesamiento digital de Señales e imágenes</option>
                            <option value="Comunicaciones y Redes">Comunicaciones y Redes</option>
                            <option value="Sistemas de Telecomunicaciones">Sistemas de Telecomunicaciones</option>
                            <option value="Internet de las Cosas (IoT)">Internet de las Cosas (IoT)</option>
                            <option value="Seguridad y Criptografía">Seguridad y Criptografía</option>
                            <option value="Telemedicina (Salud y Biomedicina)">Telemedicina (Salud y Biomedicina)</option>
                        </optgroup>
                    </select>
                    {errors.topic && (
                        <div className="invalid-feedback">
                            Este campo es requerido
                        </div>
                    )}
                </div>

                <div className="mb-3">
                    <label htmlFor="pdfArchivo" className="form-label small fw-bold">
                        Archivo PDF (PDF File) tamaño máximo 5MB:
                    </label>
                    <input
                        type="file"
                        className={`form-control ${
                            fileSizeError ? "is-invalid" : ""
                        }`}
                        id="pdfArchivo"
                        onInput={handleFileInput}
                        {...register("pdfArchivo", {
                            validate: true,
                            required: false
                        })}
                        accept=".pdf"
                    />
                    {fileSizeError && (
                        <div className="invalid-feedback">
                            El tamaño del archivo debe ser máximo de 5MB
                        </div>
                    )}
                </div>

                <div className="my-5 d-grid gap-2">
                    <button
                        type="submit"
                        className="btn btn-danger"
                        disabled={isLoading}
                    >
                        {isLoading ? "Enviando..." : "Actualizar artículo"}
                    </button>
                </div>
            </form>
        </div>
    );
}

export default ActualizarArticulo;