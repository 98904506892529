import { createContext, useCallback, useContext, useEffect, useMemo } from "react";
import PropTypes from 'prop-types';
import { api } from "../api/apiInterceptor";

import { toast } from 'react-toastify';

export const ArticuloContext = createContext();

export default function ArticuloContextProvider({children}) {

  useEffect(() => {

  }, []);

  const obtenerArticulos = useCallback(async function(data) {
    try{
      const res = await api.get(`article`);

      if(res.request.status === 200){
        /*toast.success(res.data.message, {
          position: toast.POSITION.TOP_CENTER
        });*/
        return res.data.data;
      }

    } catch (e) {

      console.log(e);
      if(e.request.status === 400){
        toast.warn(e.response.data.message, {
          position: toast.POSITION.TOP_CENTER
        });
      }
      if(e.request.status === 500){
        toast.warn(e.response.data.message, {
          position: toast.POSITION.TOP_CENTER
        });
      }
    }
  }, []);

  const crearArticulo = useCallback(async function(data) {
    try{
      const res = await api.post(`article`, data);

      if(res.request.status === 200){
        /*toast.success(res.data.message, {
          position: toast.POSITION.TOP_CENTER
        });*/
      }

    } catch (e) {
      console.log(e);
      if(e.request.status === 400){
        toast.warn(e.response.data.message, {
          position: toast.POSITION.TOP_CENTER
        });
      }
      if(e.request.status === 500){
        toast.warn(e.response.data.message, {
          position: toast.POSITION.TOP_CENTER
        });
      }
    }
  }, []);

  const actualizarArticulo = useCallback(async function(data, id) {
    try{
      const res = await api.put(`article/${id}`, data);

      if(res.request.status === 200){
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_CENTER
        });
      }

    } catch (e) {
      console.log(e);
      if(e.request.status === 400){
        toast.warn(e.response.data.message, {
          position: toast.POSITION.TOP_CENTER
        });
      }
      if(e.request.status === 500){
        toast.warn(e.response.data.message, {
          position: toast.POSITION.TOP_CENTER
        });
      }
    }
  }, []);

  const eliminarArticulo = useCallback(async function(id) {
    try{
      const res = await api.delete(`article/${id}`);

      if(res.request.status === 200){
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_CENTER
        });
      }

    } catch (e) {
      console.log(e);
      if(e.request.status === 400){
        toast.warn(e.response.data.message, {
          position: toast.POSITION.TOP_CENTER
        });
      }
      if(e.request.status === 500){
        toast.warn(e.response.data.message, {
          position: toast.POSITION.TOP_CENTER
        });
      }
    }
  }, []);

  const getPdf = useCallback(async function(pdfFileName) {
    try {
      const res = await api.getPdf(`files/${pdfFileName}`);
      //console.log({ res })
      const file = new Blob([res.data], { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);

      /*toast.success('¡Pdf encontrado!', {
        position: toast.POSITION.TOP_CENTER
      });*/

    } catch (e) {
      //console.log(e);
      toast.warn('¡El pdf que trata de buscar no existe!', {
        position: toast.POSITION.TOP_RIGHT
      });
    }
  }, []);


  const value = useMemo(
    () => ({
      // Estados

      // Métodos
      crearArticulo,
      obtenerArticulos,
      getPdf,
      actualizarArticulo,
      eliminarArticulo,
    }),
    [crearArticulo, obtenerArticulos, getPdf, actualizarArticulo, eliminarArticulo]
  );

  return <ArticuloContext.Provider value={value}>{children}</ArticuloContext.Provider>;
}

ArticuloContextProvider.propTypes = {
  children: PropTypes.object
};

export function useArticuloContext() {
  return useContext(ArticuloContext);
}
