import React from "react";
import { ToastContainer } from "react-toastify";

import { Login } from "./views/Login";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import {
  ACCOUNT_ACTIVATION,
  ACTUALIZAR_ARTICULO,
  CREAR_ARTICULO, FORGOT_PASSWORD,
  HOME,
  LOGIN,
  LOGOUT, MIS_ARTICULOS, PASSWORD_ACTIVATION,
  PRIVATE, REGISTER,
} from "./config/router/paths";

import PrivateRoute from "./components/router/PrivateRoute";
import Logout from "./views/Logout";
import Home from "./views/Home";
import PublicRoute from "./components/router/PublicRoute";
import { Navegacion } from "./components/Navigacion";
import { useAuthContext } from "./contexts/authContext";


import MisArticulos from "./views/articulo/MisArticulos";
import CrearArticulo from "./views/articulo/CrearArticulo";
import ActualizarArticulo from "./views/articulo/ActualizarArticulo";
import {Register} from "./views/Register";
import ActivarCuenta from "./views/ActivarCuenta";
import {RecordarPassword} from "./views/RecordarPassword";
import ActivarPassword from "./views/ActivarPassword";


function App() {
  const {isAuthenticated} = useAuthContext();
  return (
    <BrowserRouter>
      <ToastContainer />
      {
        isAuthenticated && <Navegacion />
      }
      <Routes>
        <Route path={PRIVATE} element={<PrivateRoute />}>
          <Route index element={<MisArticulos />} />

          {/*Rutas y componentes para Articulos*/}
          <Route path={MIS_ARTICULOS} element={<MisArticulos/>} />
          <Route path={CREAR_ARTICULO} element={<CrearArticulo/>} />
          <Route path={ACTUALIZAR_ARTICULO} element={<ActualizarArticulo/>} />

          <Route path={LOGOUT} element={<Logout />} />
        </Route>
        <Route path={HOME} element={<PublicRoute />}>
          <Route index element={<Home />} />
          <Route path={LOGIN} element={<Login />} />
          <Route path={REGISTER} element={<Register />} />
          <Route path={ACCOUNT_ACTIVATION} element={<ActivarCuenta />} />
          <Route path={FORGOT_PASSWORD} element={<RecordarPassword />} />
          <Route path={PASSWORD_ACTIVATION} element={<ActivarPassword />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
