import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import PropTypes from 'prop-types';
import { api } from "../api/apiInterceptor";
import { toast } from "react-toastify";

export const AuthContext = createContext();

export default function AuthContextProvider({children}) {

  const [isAuthenticated, setIsAuthenticated] = useState(
    window.localStorage.getItem('authentication') === 'true'
  );
  const [token, setToken] = useState(window.localStorage.getItem('token'));
  const [email, setEmail] = useState(window.localStorage.getItem("email"));
  const [name, setName] = useState(window.localStorage.getItem('name'));

  useEffect(() => {
    setToken(window.localStorage.getItem('token'));
    setEmail(window.localStorage.getItem('email'));
    setName(window.localStorage.getItem('name'));
    setIsAuthenticated(window.localStorage.getItem('authentication'));
  }, []);

  const login = useCallback(async function(data) {
      try {
        const res = await api.post('auth/login', data);
        if (res.status === 200) {
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_CENTER
          });
          const { token, email, name } = res.data;
          setToken(token);
          setEmail(email);
          setName(name);
          setIsAuthenticated(true);
          window.localStorage.setItem('token', token);
          window.localStorage.setItem('email', email);
          window.localStorage.setItem('name', name);
          window.localStorage.setItem('authentication', isAuthenticated);
          return 0;
        }
      } catch(e) {
        if (e.request.status === 401) {
          toast.warn('¡Cuenta no creada!', {
            position: toast.POSITION.TOP_LEFT
          });
        }

        if(e.request.status === 400){
          toast.warn('¡Credenciales no válidas!', {
            position: toast.POSITION.TOP_LEFT
          });
        }

        if(e.request.status === 500){
          toast.warn('¡Error al iniciar sesión!', {
            position: toast.POSITION.TOP_LEFT
          });
        }
      }
  }, [isAuthenticated]);

  const registro = useCallback(async function(data) {
    try {
      const res = await api.post('auth/register', data);
      //console.log({res})
      if (res.status === 200) {
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_CENTER
        });
      }
    } catch(e) {

      if(e.request.status === 500){
        toast.warn('¡Error al realizar registro!', {
          position: toast.POSITION.TOP_CENTER
        });
      }
    }
  }, []);

  const activateAccount = useCallback(async function(token) {
    try {
      const res = await api.get(`auth/activate-account?at=${token}`);
      console.log({res})
      if (res.status === 200) {
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_CENTER
        });
      }

    } catch(e) {
      //console.log(e)

      if(e.request.status === 409){
        toast.warn(e.response.data.message, {
          position: toast.POSITION.TOP_CENTER
        });
      }

      if(e.request.status === 500){
        toast.warn('¡Error al realizar registro!', {
          position: toast.POSITION.TOP_CENTER
        });
      }
    }
  }, []);

  const recordarPassword = useCallback(async function(data) {
    try {
      const res = await api.post('auth/forgot-password', data);
      if (res.status === 200) {
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_CENTER
        });
      }
    } catch(e) {
      if (e.request.status === 400) {
        toast.warn(e.request.data.message, {
          position: toast.POSITION.TOP_CENTER
        });
      }

      if(e.request.status === 400){
        toast.warn('¡Credenciales no válidas!', {
          position: toast.POSITION.TOP_LEFT
        });
      }

      if(e.request.status === 500){
        toast.warn('¡Error al recordar contraseña!', {
          position: toast.POSITION.TOP_LEFT
        });
      }
    }
  }, []);

  const activatePassword = useCallback(async function(token) {
    try {
      const res = await api.get(`auth/generate-new-password?at=${token}`);
      //console.log({res})
      if (res.status === 200) {
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_CENTER
        });
      }

    } catch(e) {
      //console.log(e)

      if(e.request.status === 400){
        toast.warn(e.response.data.message, {
          position: toast.POSITION.TOP_CENTER
        });
      }

      if(e.request.status === 500){
        toast.warn('¡Error al activar nueva contraseña!', {
          position: toast.POSITION.TOP_CENTER
        });
      }
    }
  }, []);

  const logout = useCallback(function () {
    window.localStorage.removeItem('token');
    window.localStorage.removeItem('email');
    window.localStorage.removeItem('name');
    window.localStorage.removeItem('authentication');
    setToken(null);
    setEmail(null);
    setName(null);
    setIsAuthenticated(false);
    window.localStorage.clear();
  }, []);


  const value = useMemo(
    () => ({
      // Estados
      isAuthenticated,
      token,
      email,
      name,

      // Métodos
      login,
      logout,
      registro,
      activateAccount,
      recordarPassword,
      activatePassword,
    }),
    [isAuthenticated, token, email, name, login, logout, registro, activateAccount, recordarPassword, activatePassword]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

AuthContextProvider.propTypes = {
  children: PropTypes.object
};

export function useAuthContext() {
  return useContext(AuthContext);
}
