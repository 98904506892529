import { Link } from "react-router-dom";
import {
  CREAR_ARTICULO,
  MIS_ARTICULOS,
  LOGOUT,
} from "../config/router/paths";
import { useAuthContext } from "../contexts/authContext";

export const Navegacion = () => {
  const {isAuthenticated} = useAuthContext();
  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-danger">
      <div className="container">
        <img src="escudo_62_anios.png" alt="" height="70" className="d-inline-block align-text-top"/>
        <Link className="navbar-brand fw-bold" to="/private">CONGRESO CIETA</Link>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
                aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav ms-auto">

            <li className="nav-item dropdown">
              <span className="nav-link dropdown-toggle active" id="articulosDropdown" role="button"
                    data-bs-toggle="dropdown" aria-expanded="false">
                Artículos
              </span>
              <ul className="dropdown-menu" aria-labelledby="articulosDropdown">
                <li><Link className="dropdown-item" to={MIS_ARTICULOS}>Mis artículos</Link></li>
                <li><Link className="dropdown-item" to={CREAR_ARTICULO}>Nuevo artículo</Link></li>
              </ul>
            </li>

            {/*<li className="nav-item ms-5">
              {
                isAuthenticated && <Link className="nav-link" to="#">Mi cuenta</Link>
              }
            </li>*/}
            <li className="nav-item ms-1">
              {
                isAuthenticated && <Link className="nav-link active" to={LOGOUT}>Cerrar Sesión</Link>
              }
            </li>
          </ul>
        </div>


      </div>
    </nav>
  );
};
