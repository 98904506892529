export const HOME = '/';
export const LOGIN = '/login';
export const REGISTER = '/register';
export const ACCOUNT_ACTIVATION = '/activar';

export const FORGOT_PASSWORD = '/forgot_password';
export const PASSWORD_ACTIVATION = '/nueva-contrasena';

export const PRIVATE = '/private';

export const MIS_ARTICULOS = '/private/get-articles';
export const CREAR_ARTICULO = '/private/create-article';
export const ACTUALIZAR_ARTICULO = '/private/update-article';

export const LOGOUT = '/private/logout';
